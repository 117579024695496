/*
 * @Descripttion:
 * @Author: congz
 * @Date: 2020-07-20 11:08:33
 * @LastEditors: congz
 * @LastEditTime: 2020-08-05 15:32:59
 */
import axios from 'axios'
import request from '@/utils/request.js'

// 创建购物车
// const postAddress = form =>
//   axios.post('/api/v1/addresses', form).then(res => res.data)
const postAddress = form => request({
	url: '/user/contact',
	method: 'post',
	data: form
})

// 读取购物车
// const showAddresses = user_id =>
//   axios.get(`/api/v1/addresses/${user_id}`).then(res => res.data)
const showAddresses = (query) => request({
	url: '/user/contact',
	method: 'get',
	params: query ?? {}
})

// 更新购物车
// const updateAddress = form =>
//   axios.put('/api/v1/addresses', form).then(res => res.data)
const updateAddress = (id, form) => request({
	url: '/user/contact/' + id,
	method: 'put',
	data: form
})

// 删除购物车
// const deleteAddress = addressID =>
//   axios
//     .delete('/api/v1/addresses', {
//       data: { address_id: addressID }
//     })
//     .then(res => res.data)
const deleteAddress = id => request({
	url: '/user/contact/' + id,
	method: 'delete'
})

export { postAddress, showAddresses, updateAddress, deleteAddress }
